import React from 'react';
import TextBlock from '../components/TextBlock';
import PageHeader from '../components/PageHeader';

export default function AboutPage() {

    const content = [
        "Eduscape is a joint venture between Pier Pressure’s Phil and Philly and ex-science teacher Emily.",
        "Emily loved teaching from a young age, tutoring children for their 11+ and GCSEs since passing her own GCSEs. She went on to complete a Biochemistry degree and PGCE in order to become a qualified science teacher. During this time, she also discovered her love for escape rooms, completing her first in 2019 at Pier Pressure.",
        "Emily writes:",
        "‘I was always looking for a way to make my lessons more fun and interactive to keep students engaged with challenging content, particularly for revision lessons. After completing several escape rooms, I thought it would be a great way for students to use their knowledge, feel successful, and revise in a fun memorable way. I initially made a paper-based escape room for the Quantitative Chemistry unit for my year 10 class. Other teachers also used the lesson and it was a great success, motivating classes to learn content to compete in this knowledge-based game. From there I had a vision of an immersive physical room with curriculum content built in. I designed escape room puzzles to match exam content and skills for the biology unit ,Cells and Transport. I approached Phil and Philly as Pier Pressure’s escape rooms are some of my favorites and I knew that if they loved this idea as much as I did, there was no one better to help bring it to life.’",
        "Pier Pressure – run by husband-and-wife team Phil and Philly - are Brighton’s award-winning, five-star rated escape games, operating at three locations and currently offering nine different experiences.",
        "Philly from Pier Pressure writes:",
        "‘We opened our doors with just one game in 2018 and have been steadily growing and receiving excellent reviews for our unique, Brighton-themed games. We love all things game and fun, and are always looking for new opportunities to spread the joy and energy rush that escape rooms bring.",
        "So when Emily approached us with her idea to bring escape rooms to the classroom to aid exam revision, we were instantly sold!",
        "She came to us with an entirely designed first game, using her experience as a secondary school teacher, knowledge of the subjects and exam topics, and a vision to create a hands-on, interactive experience that helps bring learning outcomes to life.",
        "We loved her passion for escape rooms which we obviously share, and her creativity that allowed her to design a Pier-Pressure-standard game that we started building straight away!’"
    ]
    
    return (
    <div>
        <PageHeader title='About Us'/>
        <div className='container py-6'>
            <section className='section'>
                <div className='columns'>
                    <div className='column is-two-thirds'>
                        <TextBlock text={content} wrapperClassName='is-two-thirds'/>
                    </div>
                    <div className='column'>
                        <figure className='is-3by2'>
                            <img src='/pier_pressure_and_eduscape.jpg' alt='pier pressure and eduscape'></img>
                        </figure>
                    </div>
                </div>
            </section>
        </div>
    </div>
    )
}