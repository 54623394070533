import React from 'react'
import { Link } from 'react-router-dom'

interface RoomCardProps {
    title: string
    imagePath: string
    altText: string
    subtitle?: string
    button?: boolean
    comingSoon?: boolean
}

export default function RoomCard(props: RoomCardProps) {
    return (
        <div className='card transparent-background is-shadowless'>
            <div className="card-image">
                <figure className="image is-5by4">
                    <img src={props.imagePath} alt={props.altText}/>
                </figure>
            </div>
            <div className='is-overlay card-overlay'>
                <p className="title has-text-black">{props.title}</p>
                { props.subtitle && <p className='subtitle has-text-black'>{props.subtitle}</p> }
            </div>
            { props.comingSoon &&
                <img src="./coming_soon.png" alt="Coming Soon Image" className="coming-soon"/>
            }
            { props.button &&
                <div>
                    <Link className='button is-primary is-light is-medium card-button mt-4' to='/contact'><b>Contact Us Now</b></Link>
                </div>
            }

        </div>
    )
}