import React from 'react';
import PageHeader from '../components/PageHeader';
import ChildTile from '../components/ChildTile';

export default function LogisticsPage() {
    return (
        <div>
            <PageHeader title='Logistics' subtitle='Useful things to know when booking'/>
            <div className='container has-text-centered'>
                <div className='section'>
                    <div className='tile is-ancestor'>
                        <ChildTile title='Players Per Group' content={['6-8']} size='is-3' imagePath='./group.png'/>
                        <ChildTile title='Length of Game:' content={['45 minutes']} size='is-3' imagePath='./time.png'/>
                        <ChildTile title='Time Required to Set Up:' content={['60-90 minutes']} size='is-3' imagePath='./setup.png'/>
                        <ChildTile title='Teacher Required?' content={['Preferably a teacher present throughout sessions but as a minimum a member of staff is required to be present.']} size='is-3' imagePath='teacher.png'/>
                    </div>
                    <div className='tile is-ancestor'>
                        <ChildTile title='Ideal Space Required:' content={['30+ sqm room.', 'Ground floor or with lift access.','6x mains plug sockets.', 'Ability to darken the room.']} size='is-6' imagePath='space.png'/>
                        <ChildTile title='Groups Per Day:' content={['Up to 6, but it will depend on school timetable structure.']} size='is-6' imagePath='day.png'/>
                    </div>
                    <div className='tile is-ancestor'>
                        <ChildTile title='Cost:' content={['Contact us for a quote.', 'Discounts given for multiple days.']} imagePath='cost.png'/>
                    </div>
                </div>
            </div>
        </div>
    )
}