import React from 'react';
import PageHeader from '../components/PageHeader';
import ExperienceCard from '../components/ExperienceCard';


const specification=[
    "Identify and name the sub-cellular structures in animal, plant and bacterial cells.",
    "Describe functions of sub-cellular structures in plant and animal cells",
    "Identify and name specialised cells including nerve cell, red blood cell, sperm cell, root hair cell and xylem",
    "Describe how specialised cells are adapted to their function",
    "Calculate actual size using the magnification calculation I=AM",
    "Measure accurately",
    "Convert between units, cm to mm, mm to µm",
    "Calculate percentage gain and loss of mass from the osmosis in potatoes practical, accurately plot a graph with the data and extrapolate data from the graph",
    "Describe the three ways substances can move between cells: diffusion, active transport and osmosis",
    "Provide examples for diffusion, active transport and osmosis",
    "Explain the effect of temperature, surface area and concentration on the rate of diffusion"
]

export default function ExperiencesPage() {
    return (
        <div>
            <PageHeader title='Our Experiences'/>
            <div className='container py-6'>
                <div className="columns">
                    <div className="column">
                        <ExperienceCard
                            title='Cells and Transport'
                            imagePath='./biology.png'
                            altText='Biology escape room puzzle photo'
                            difficulty='GCSE Biology'
                            summary="The year is 2538, humans have moved to inhabit a new planet and our team of expert Biologists need to rebuild the blueprints to life by using their knowledge of cells and transport."
                            specification={specification}
                        />
                    </div>
                    <div className="column">
                        <ExperienceCard
                            title='GCSE Physics'
                            imagePath='./new_course.jpg'
                            altText='Upcoming escape room puzzle'
                            summary='Our GCSE Physics escape room puzzle will be coming soon! Come back here for more details later.'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}